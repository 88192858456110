<template>
  <img
    src="/img/logo-degradient.svg"
    alt="Workflow"
    class="m-auto image"
    width="50%"
  />
</template>

<script>
export default {
  name: "Logo",
};
</script>
